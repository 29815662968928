// /**
//  * Resultados da busca:
//  * Página que recebe as funcionalidades de busca (ElasticSearch) e filtros
//  *
//  * Existem condicionais na exibição e funcionamento diferente dos filtros para
//  * mobile e desktop. Exibição controlada com <MediaQuery> e com valores de window.width
//  * As categorias estão sendo exibidas dinamicamente de acordo com o resultado da
//  * função groupBy do lodash.
//  *
//  * TO DO: Verificar porque está executando novamente o render do componente
//  * de filtros em qualquer atualização de estados.
//  */
import React, { useState } from 'react'
import { IoIosRemove, IoIosAdd } from 'react-icons/io'
// import { Link } from 'gatsby'
import styled from 'styled-components'
import './../components/reset.css'
import { isMobile } from 'react-device-detect'

/**
 * Componentes externos utilizados nesta página:
 */
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Loading } from '@monorepo-amais/commons/components/Search'
import Pagination from '@monorepo-amais/commons/components/Blocks/Pagination'

import queryString from 'query-string'
import { MainTitle } from '@monorepo-amais/commons/components/Typography'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

import { graphql } from 'gatsby'
import { Search } from '@monorepo-amais/commons/components/Inputs'
import { FlexDiv, MainContainer } from '@monorepo-amais/commons/components/Blocks'
import { Icon } from '@monorepo-amais/commons/components/Buttons'
import Fuse from 'fuse.js'

/**
 * Estilos gerais dos componentes criados com styled-components:
 */

const Separator = styled.hr`
	display: ${({ visilibity }) => (visilibity ? 'block' : 'none')};
	border: none;
	background: ${props => props.theme.colors.graye2};
	height: 1px;
	width: 100%;
	margin-left: 7%;
`

const ButtonWithoutStyle = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	width: 100%;
`

const CardContainer = styled.div`
	padding: 20px;
	margin: 20px;
	border-radius: 3px;
	border: 1px solid ${props => props.theme.colors.graye2};
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;

	:first-child {
		margin: 0 20px 20px;
	}

	:last-child {
		margin: 20px 20px 0;
	}

	@media (max-width: 764px) {
		margin: 20px 6px;
		:first-child {
			margin: 0 6px 20px 6px;
		}

		:last-child {
			margin: 20px 6px 0 6px;
		}
	}
`

const CardContent = styled(FlexDiv)`
	width: 100%;
`

const Number = styled.span`
	font-size: 40px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.primary};
	@media (max-width: 764px) {
		font-size: 20px;
	}
`

const CardFooter = styled.span`
	display: ${({ visilibity }) => (visilibity ? 'block' : 'none')};
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-left: 7%;

	@media (max-width: 764px) {
		font-size: 12px;
	}
`

const SearchArea = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	height: 258px;
	border: none;
	background: ${props => props.theme.colors.grayf1};
	border-radius: unset;
	border-radius: 5px;
	text-align: center;
	margin-top: 20px;

	.title {
		font-size: 40px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 0.45;
		letter-spacing: normal;
		text-align: center;
		color: ${props => props.theme.colors.primary};
		margin: 40px 0;
	}

	span {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.13;
		letter-spacing: normal;
		text-align: center;
		color: ${props => props.theme.colors.gray66};
	}

	@media (max-width: 764px) {
		height: 80px;
	}
`

const Content = styled.div`
	margin-top: 318px;

	@media (max-width: 764px) {
		margin-top: 120px;
	}
`

const CustomFlexDiv = styled(FlexDiv)`
	flex-flow: column;
	padding: 0 26%;
	@media (max-width: 1024px) {
		padding: 0 18%;
	}
	@media (max-width: 764px) {
		justify-content: flex-end;
		padding: 10px;
		& > span {
			display: none;
		}
	}
`

const Question = styled.span`
	border-left: solid 1px ${props => props.theme.colors.graye2};
	margin: auto auto auto 20px;
	padding: 0 20px;
	flex: 1;
	white-space: pre;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	justify-content: flex-start;
	span {
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: normal;
		text-align: left;
		color: ${props => props.theme.colors.gray66};
		white-space: normal;

		@media (max-width: 764px) {
			font-size: 15px;
		}
	}

	@media (max-width: 764px) {
		margin: auto auto auto 10px;
		padding: 0 10px;
	}
`

const AddIcon = styled(IoIosAdd)`
	width: 35px;
	height: 35px;
	object-fit: contain;
	color: ${props => props.theme.colors.primary};

	@media (max-width: 764px) {
		width: 25px;
		height: 25px;
	}
`

const RemoveIcon = styled(IoIosRemove)`
	width: 35px;
	height: 35px;
	object-fit: contain;
	color: ${props => props.theme.colors.primary};

	@media (max-width: 764px) {
		width: 25px;
		height: 25px;
	}
`

const Faq = ({ location, data }) => {
	/**
	 * Declaração de variavéis e definição de estados inicias:
	 */
	let searchParam = queryString.parse(location.search).q

	const initialState = data.allCosmicjsPerguntasFrequentes.edges.map(({ node }, index) => ({
		answer: node.metadata.answer,
		order: index,
		...node
	}))

	if (!searchParam) searchParam = ''

	const [cardOpened, setCardOpened] = useState(null)
	const [results, setResults] = useState(initialState)
	const [loading] = useState(false)
	const [page, setPage] = useState(1)
	const initTotalPages =
		initialState.length % 10 === 0 && initialState.length > 10
			? initialState.length / 10
			: parseInt(initialState.length / 10) + 1
	const [totalPages, setTotalPage] = useState(initTotalPages)

	const fuseQuestions = new Fuse(initialState, {
		keys: ['question', 'answer'],
		caseSensitive: false,
		minMatchCharLength: 1,
		threshold: 0.4
	})

	const handleChange = e => {
		const { value } = e.target
		const newResults = value !== '' ? fuseQuestions.search(value) : initialState
		setCardOpened(null)
		setResults(newResults.map((item, index) => ({ ...item, order: index })))
		const newTotalPages =
			newResults % 10 === 0 && newResults > 10 ? newResults.length / 10 : parseInt(newResults / 10) + 1
		setTotalPage(newTotalPages)
	}

	const elementToRender = ({ order, question, answer, id }) => (
		<CardContainer key={id}>
			{/* {_source.metadata && (
				<CardLink
					to={
						_source.metadata.idLegado
							? `/${_source.type_slug}/${_source.metadata.idLegado}`
							: `/${_source.type_slug}/${_source.slug}`
					}
				></CardLink>
			)} */}
			{/* { props.image && <CardImage src={props.image} alt={props.title} /> } */}
			<ButtonWithoutStyle onClick={() => setCardOpened(cardOpened === order ? null : order)}>
				<CardContent justify='space-between'>
					<Number>{order >= 9 ? order + 1 : `0${order + 1}`}.</Number>
					<Question>
						<span>{question}</span>
					</Question>
					<Icon showOnMobile={true}>{order === cardOpened ? <RemoveIcon /> : <AddIcon />}</Icon>
					<Separator visilibity={order === cardOpened} />
					<CardFooter visilibity={order === cardOpened} dangerouslySetInnerHTML={{ __html: answer }} />
				</CardContent>
			</ButtonWithoutStyle>
		</CardContainer>
	)

	const handleNextPage = selectedPage => {
		setPage(selectedPage)
		return []
	}

	const mode = isMobile ? 'mobile' : 'desktop'

	const offset = (page - 1) * 10

	return (
		<>
			<Layout footer={data.footer} regionais={data.regionais}>
				<SEO
					title={data.cosmicjsInformacoesEstaticas.metadata.seo.titulo}
					description={data.cosmicjsInformacoesEstaticas.metadata.seo.descricao}
					image={imgixUrlOptimizerAuto(data.cosmicjsInformacoesEstaticas.metadata.seo.imagem.imgix_url)}
				/>
				{loading && <Loading />}
				<MainContainer>
					{/* <Breadcrumb>
						<li>
							<Link to='/'>Home</Link>
						</li>
						<li className='current'> FAQ </li>
					</Breadcrumb> */}
					<MainTitle>{data.frequentes.metadata.titulo}</MainTitle>

					<SearchArea>
						<CustomFlexDiv>
							<span className='title'>{data.frequentes.metadata.tituloajuda}</span>
							<Search id='search-faq' label={data.frequentes.metadata.placeholder} onChange={handleChange} />
							<span>{data.frequentes.metadata.ajuda_texto}</span>
						</CustomFlexDiv>
					</SearchArea>

					<Content>
						<Pagination
							elementToRender={elementToRender}
							elementsPerPage={10}
							handleNextPage={handleNextPage}
							totalPages={totalPages}
							mode={mode}
							items={results.slice(offset, offset + 10)}
							page={page}
						/>
						{/* ) : null}
							{results !== null || loading || firstLoading ? null : (
								<NoResultsFound>
									<p className='title'> Nenhum resultado encontrado </p>
									<p className='text'>Sua busca por '{querySearch}' não retornou resultados. Tente novamente com outros termos.</p>
								</NoResultsFound>
							)} */}
					</Content>
				</MainContainer>
			</Layout>
		</>
	)
}

export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(title: { eq: "Perguntas Frequentes" }) {
			metadata {
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
			}
		}
		footer: cosmicjsInformacoesEstaticas(title: { eq: "Footer" }) {
			#usando alias pra n dar conflito
			metadata {
				responsavel_tecnico
				telefones_footer {
					estado
					telefone
					link_whatsapp
				}
				loja {
					android {
						imagem {
							url
						}
						link
					}
					apple {
						imagem {
							url
						}
						link
					}
				}
			}
		}
		regionais: cosmicjsInformacoesEstaticas(title: { eq: "Regionais" }) {
			metadata {
				telefones {
					estado
					telefone
					sigla
				}
			}
		}
		allCosmicjsPerguntasFrequentes {
			edges {
				node {
					id
					metadata {
						answer: resposta
					}
					question: title
				}
			}
		}

		frequentes: cosmicjsInformacoesEstaticas(title: { eq: "Perguntas Frequentes" }) {
			metadata {
				titulo
				ajuda_texto
				tituloajuda
				placeholder
				seo {
					titulo
					imagem {
						imgix_url
						url
					}
				}
			}
		}
	}
`

// GAMB MONSTRA
// const Faq = () => <>faq</>
export default Faq
